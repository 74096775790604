class IndustryReportItem {
  constructor(
    companyName = "",
    companyId = "",
    youtube = {},
    facebook = {}
  ) {
    this.companyName = companyName;
    this.companyId = companyId;

    // Use optional chaining (?.) to safely access properties
    this.totalPosts = facebook?.articles || 0;
    this.totalReactions = facebook?.likes || 0;
    this.totalComments = facebook?.comments || 0;
    this.totalShares = facebook?.shares || 0;
    this.totalStats = facebook?.total || 0;

    this.youtubeLikes = youtube?.likes || 0;
    this.youtubeViews = youtube?.views || 0;
    this.youtubeComments = youtube?.comments || 0;
    this.youtubeVideos = youtube?.videos || 0;
    this.youtubeSubs = youtube?.subscribers || 0;

    this.youtubeEngagement = 0;
    this.instaPosts = 0;
    this.instaReactions = 0;
    this.instaShares = 0;
    this.instaComments = 0;
    this.instaTotals = 0;
  }

  static fromJson(json) {
    return new IndustryReportItem(
      json.companyName,
      json.companyId,
      json.media,
      json.youtube,
      json.facebook
    );
  }
}

export default IndustryReportItem;
